import React, { useState, useEffect } from 'react';
import axios from 'axios';
import img from '../Images/about2.jpeg'; 
import { FaCopy } from 'react-icons/fa';
import Sidebar from '../Components/Sidebar';
import ContactForm from './ContactForm';

const Toast = ({ message, duration, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className="fixed bottom-5 right-5 bg-orange-500 text-white p-3 rounded shadow-lg">
      {message}
    </div>
  );
};

const UserProfile = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [restaurantName, setRestaurantName] = useState('');
  const [restaurantAddress, setRestaurantAddress] = useState('');
  const [restaurantContactNumber, setRestaurantContactNumber] = useState('');
  const [restaurantInformation, setRestaurantInformation] = useState('');
  const [availableTables, setAvailableTables] = useState('');
  const [openingHours, setOpeningHours] = useState('');
  const [closingHours, setClosingHours] = useState('');
  const [website, setWebsite] = useState('');
  const [seatingCapacity, setSeatingCapacity] = useState('');
  const [uniqueUrl, setUniqueUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
 const [publicUrl, setPublicUrl] = useState('');

 

  const [errors, setErrors] = useState({
    availableTables: '',
    seatingCapacity: '',
    openingHours: '',
    closingHours: '',
  });

  const [toastVisible, setToastVisible] = useState(false);

  const handleInputChange = (setter, value) => {
    setter(value);
  };

  const validateInteger = (value, field) => {
    if (!value || isNaN(parseInt(value, 10))) {
      return `${field} must be a valid integer.`;
    }
    return '';
  };

  const validateURL = (value) => {
    try {
      new URL(value);
      return '';
    } catch (_) {
      return 'Enter a valid URL.';
    }
  };

  const validateTime = (value, field) => {
    const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?([.,]\d+)?$/;
    if (!timeFormatRegex.test(value)) {
      return `${field} has the wrong format. Use hh:mm or hh:mm:ss.`;
    }
    return '';
  };

  const validateFields = () => {
    const newErrors = {
      availableTables: validateInteger(availableTables, 'Available tables'),
      seatingCapacity: validateInteger(seatingCapacity, 'Seating capacity'),
      openingHours: validateTime(openingHours, 'Opening hours'),
      closingHours: validateTime(closingHours, 'Closing hours'),
    };

    setErrors(newErrors);
    return Object.keys(newErrors).every((key) => newErrors[key] === '');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(uniqueUrl)
      .then(() => {
        setToastVisible(true); 
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const copyToClipboard1 = () => {
    navigator.clipboard.writeText(publicUrl)
      .then(() => {
        setToastVisible(true);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateFields()) {
      return;
    }
  
    setIsLoading(true); // Start loading
  
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in local storage');
        setIsLoading(false);
        return;
      }
  
      const updatedData = {
        username,
        email,
        restaurant_name: restaurantName,
        restaurant_address: restaurantAddress,
        restaurant_contact_number: restaurantContactNumber,
        restaurant_information: restaurantInformation,
        available_tables: availableTables,
        opening_hours: openingHours,
        closing_hours: closingHours,
        website,
        seating_capacity: seatingCapacity,
        unique_url: uniqueUrl,
        public_profile_url: publicUrl,
      };
  
      const response = await axios.put('https://backend.techionik.com/api/profile/', updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Profile updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating profile data:', error.response ? error.response.data : error.message);
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      }
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found in local storage');
          return;
        }

        const response = await axios.get('https://backend.techionik.com/api/profile/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUsername(response.data.username);
        setEmail(response.data.email);
        setRestaurantName(response.data.restaurant_name);
        setRestaurantAddress(response.data.restaurant_address);
        setRestaurantContactNumber(response.data.restaurant_contact_number);
        setRestaurantInformation(response.data.restaurant_information);
        setAvailableTables(response.data.available_tables);
        setOpeningHours(response.data.opening_hours);
        setClosingHours(response.data.closing_hours);
        setWebsite(response.data.website);
        setSeatingCapacity(response.data.seating_capacity);
        setUniqueUrl(response.data.unique_url);
        setPublicUrl(response.data.public_profile_url);
      } catch (error) {
        console.error('Error fetching profile data:', error.response ? error.response.data : error.message);
      }
    };

    fetchProfileData();
  }, []);

  return (
    <div className="lg:w-full md:w-full w-full bg-white rounded-lg p-5">
      {/* <div style={{display:'none'}}>
        <ContactForm uniqueUrl={uniqueUrl} />
      </div> */}
      <div className="w-full text-center">
        <h2 className="text-3xl font-semibold text-gray-800 flex items-center justify-center gap-2 mb-8">Profile</h2>
      </div>
      <div className="space-y-28">
        <div className="flex flex-col items-center justify-center mb-8">
          <div className="w-40 h-40 flex justify-center">
            <img src={img} alt="User profile" className="rounded-full w-40 h-40" />
          </div>
        </div>
        <form className="contact-form flex justify-center flex-wrap gap-6" onSubmit={handleSubmit}>
          <input
            id="username"
            value={username}
            onChange={(e) => handleInputChange(setUsername, e.target.value)}
            className="input-text w-full p-3 border-b-2 border-b-orange-500"
            placeholder="User Name"
          />
          <input
            id="email"
            value={email}
            onChange={(e) => handleInputChange(setEmail, e.target.value)}
            className="input-text w-full p-3 border-b-2 border-b-orange-500"
            placeholder="Email"
          />
          <input
            id="restaurantName"
            value={restaurantName}
            onChange={(e) => handleInputChange(setRestaurantName, e.target.value)}
            className="input-text w-full p-3 border-b-2 border-b-orange-500"
            placeholder="Restaurant Name"
          />
          <input
            id="restaurantAddress"
            value={restaurantAddress}
            onChange={(e) => handleInputChange(setRestaurantAddress, e.target.value)}
            className="input-text w-full p-3 border-b-2 border-b-orange-500"
            placeholder="Restaurant Address"
          />
          <input
            id="restaurantContactNumber"
            value={restaurantContactNumber}
            onChange={(e) => handleInputChange(setRestaurantContactNumber, e.target.value)}
            className="input-text w-full p-3 border-b-2 border-b-orange-500"
            placeholder="Restaurant Contact Number"
          />
          <input
            id="restaurantInformation"
            value={restaurantInformation}
            onChange={(e) => handleInputChange(setRestaurantInformation, e.target.value)}
            className="input-text w-full p-3 border-b-2 border-b-orange-500"
            placeholder="Restaurant Information"
          />
          <input
            id="availableTables"
            value={availableTables}
            onChange={(e) => handleInputChange(setAvailableTables, e.target.value)}
            className="input-text w-full p-3 border-b-2 border-b-orange-500"
            placeholder="Available Tables"
          />
          {errors.availableTables && <span className="text-red-500">{errors.availableTables}</span>}
          <input
            id="openingHours"
            value={openingHours}
            onChange={(e) => handleInputChange(setOpeningHours, e.target.value)}
            className="input-text w-full p-3 border-b-2 border-b-orange-500"
            placeholder="Opening Hours"
          />
          {errors.openingHours && <span className="text-red-500">{errors.openingHours}</span>}
          <input
            id="closingHours"
            value={closingHours}
            onChange={(e) => handleInputChange(setClosingHours, e.target.value)}
            className="input-text w-full p-3 border-b-2 border-b-orange-500"
            placeholder="Closing Hours"
          />
          {errors.closingHours && <span className="text-red-500">{errors.closingHours}</span>}
          <input
            id="website"
            value={website}
            onChange={(e) => handleInputChange(setWebsite, e.target.value)}
            className="input-text w-full p-3 border-b-2 border-b-orange-500"
            placeholder="Website"
          />
          {errors.website && <span className="text-red-500">{errors.website}</span>}
          <input
            id="seatingCapacity"
            value={seatingCapacity}
            onChange={(e) => handleInputChange(setSeatingCapacity, e.target.value)}
            className="input-text w-full p-3 border-b-2 border-b-orange-500"
            placeholder="Seating Capacity"
          />
          {errors.seatingCapacity && <span className="text-red-500">{errors.seatingCapacity}</span>}
          <div className="relative w-full">
            <input
              id="uniqueUrl"
              value={uniqueUrl}
              onChange={(e) => handleInputChange(setUniqueUrl, e.target.value)}
              className="input-text w-full p-3 border-b-2 border-b-orange-500"
              placeholder="Unique URL"
              readOnly
            />
            <button
        type="button"
        onClick={copyToClipboard}
        className="absolute right-3 top-1/2 flex justify-center  transform -translate-y-1/2 bg-orange-500 text-white p-2 rounded-md hover:bg-orange-700"
      >
          <span className='mx-1 font-bold '>copy</span> <FaCopy size={21} />
      </button>
            
          </div>
          <div className="relative w-full">
            <input
              id="publicUrl"
              value={publicUrl}
              onChange={(e) => handleInputChange(setPublicUrl, e.target.value)}
              className="input-text w-full p-3 border-b-2 border-b-orange-500"
              placeholder="Public URL"
              readOnly
            />
            <button
        type="button"
        onClick={copyToClipboard1}
        className="absolute right-3 top-1/2 flex justify-center  transform -translate-y-1/2 bg-orange-500 text-white p-2 rounded-md hover:bg-orange-700"
      >
          <span className='mx-1 font-bold '>copy</span> <FaCopy size={21} />
      </button>
            
          </div>
          <button type="submit" className="submit-btn bg-orange-500 text-white py-2 px-8" disabled={isLoading}>
  {isLoading ? 'Updating...' : 'Update Profile'}
</button>
        </form>
      </div>
      {toastVisible && <Toast message="URL copied!" duration={2000} onClose={() => setToastVisible(false)} />}
    </div>
  );
};

export default UserProfile;
