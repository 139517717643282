import React, { useState } from 'react';
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import img from '../Images/about2.jpeg';

const Costumers = () => {
  const totalCustomers = 6; // Total number of customers
  const customersPerPage = 4; // Number of customers per page
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getVisibleCustomers = () => {
    const startIndex = (currentPage - 1) * customersPerPage;
    const endIndex = startIndex + customersPerPage;
    return Array(totalCustomers).fill().slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(totalCustomers / customersPerPage);

  return (
    <div className="w-full bg-white rounded-lg mmmmm p-5 md:p-6 lg:p-8">
      <div className="p-5">
        {/* Title Section */}
        <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-5">
          <h2 className="text-xl md:text-2xl font-bold mb-2 md:mb-0">
            CUSTOMERS
          </h2>
          <button className="bg-orange-500 text-white px-4 py-2 rounded text-sm md:text-base">
            ALL CUSTOMERS
          </button>
        </div>

        {/* Customers List */}
        <div className="space-y-4">
          {getVisibleCustomers().map((_, index) => (
            <div
              key={index}
              className={`flex flex-col md:flex-row items-center justify-between p-4 border border-gray-200 rounded-lg ${
                index === 1 ? 'bg-white' : 'bg-white'
              }`}
            >
              {/* Profile Section */}
              <div className="flex items-center space-x-3 mb-4 md:mb-0">
                <img
                  src={img} // Placeholder for avatar
                  alt="User Avatar"
                  className="w-10 h-10 rounded-full"
                />
                <div>
                  <p className="font-semibold">Frederick V.</p>
                </div>
              </div>

              {/* Customer Details */}
              <div className="flex flex-wrap gap-4 justify-start md:justify-center text-gray-700 mb-4 md:mb-0">
                <div className="flex items-center text-sm md:text-base lg:border-l-2 lg:border-l-slate-950 px-2">
                  <FaPhoneAlt className='text-orange-500' size={25} />
                  <p className="font-medium">+44 795 325432</p>
                </div>
                <div className="flex items-center text-sm md:text-base lg:border-l-2 lg:border-l-slate-950 px-2">
                  <MdEmail className='text-orange-500' size={25} />
                  <span className='font-medium'>joshua@gmail.com</span>
                </div>
              </div>

              {/* Menu and More Options */}
              <div className="flex space-x-2">
                <button className="text-gray-500">
                  <i className="fas fa-ellipsis-h"></i>
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <div className="flex flex-wrap justify-center items-center mt-5 gap-2">
          {currentPage > 1 && (
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className="bg-gray-200 px-3 py-1 rounded text-gray-500 text-sm md:text-base"
            >
              &lt;
            </button>
          )}
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`px-3 py-1 border border-gray-300 rounded text-gray-500 text-sm md:text-base ${
                currentPage === index + 1 ? 'bg-orange-500 text-white' : ''
              }`}
            >
              {index + 1}
            </button>
          ))}
          {currentPage < totalPages && (
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="bg-gray-200 px-3 py-1 rounded text-gray-500 text-sm md:text-base"
            >
              &gt;
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Costumers;
