import React, { useState } from 'react';
import axios from 'axios'; // Import axios
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import { Link, useNavigate } from 'react-router-dom';
import img from '../Images/AI_RESERVATION_LOGO-02-removebg-preview.png';

const Signup = () => {
  const navigate = useNavigate();

  // State to manage form data
  const [user, setUser] = useState({
    username: '',
    email: '',
    restaurant_name: '',
    restaurant_address: '',
    restaurant_contact_number: '',
    password: '',
    confirm_password: '',
  });

  // State to manage the visibility of passwords
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  // Function to toggle password visibility
  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from refreshing
    if (user.password !== user.confirm_password) {
      alert('Passwords do not match');
      return;
    }

    try {
      const result = await axios.post(
        "https://backend.techionik.com/api/signup/",
        {
          username: user.username,
          email: user.email,
          restaurant_name: user.restaurant_name,
          restaurant_address: user.restaurant_address,
          restaurant_contact_number: user.restaurant_contact_number,
          password: user.password,
          confirm_password: user.confirm_password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      
      console.log("User signed up successfully:", result.data);
      navigate("/signin");
    } catch (error) {
      console.error("Error signing up:", error);
      alert('Sign up failed');
    }
  };

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-4 lg:px-8">
      <div className=" ">
        <div className="sm:mx-auto sm:w-full shadow-md border-2 rounded-md p-4 sm:max-w-sm">
          <img className="mx-auto h-10 w-auto" src={img} alt="Your Company" />
          <h2 className="mt-10 text-2xl font-bold leading-9 tracking-tight text-orange-500">Sign Up</h2>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="restaurant_name" className="block text-sm font-medium leading-6 text-gray-900">Restaurant Name</label>
              <div className="mt-2">
                <input
                  id="restaurant_name"
                  name="restaurant_name"
                  type="text"
                  value={user.restaurant_name}
                  onChange={handleInputChange}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="restaurant_address" className="block text-sm font-medium leading-6 text-gray-900">Restaurant Address</label>
              <div className="mt-2">
                <input
                  id="restaurant_address"
                  name="restaurant_address"
                  type="text"
                  value={user.restaurant_address}
                  onChange={handleInputChange}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="restaurant_contact_number" className="block text-sm font-medium leading-6 text-gray-900">Restaurant Contact</label>
              <div className="mt-2">
                <input
                  id="restaurant_contact_number"
                  name="restaurant_contact_number"
                  type="text"
                  value={user.restaurant_contact_number}
                  onChange={handleInputChange}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">User Name</label>
              <div className="mt-2">
                <input
                  id="username"
                  name="username"
                  type="text"
                  value={user.username}
                  onChange={handleInputChange}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={user.email}
                  onChange={handleInputChange}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="relative">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type={showPassword.password ? 'text' : 'password'}
                  value={user.password}
                  onChange={handleInputChange}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div
                  className="absolute right-3 top-9 cursor-pointer"
                  onClick={() => togglePasswordVisibility('password')}
                >
                  {showPassword.password ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>

            <div className="relative">
              <label htmlFor="confirm_password" className="block text-sm font-medium leading-6 text-gray-900">Confirm Password</label>
              <div className="mt-2">
                <input
                  id="confirm_password"
                  name="confirm_password"
                  type={showPassword.confirmPassword ? 'text' : 'password'}
                  value={user.confirm_password}
                  onChange={handleInputChange}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div
                  className="absolute right-3 top-9 cursor-pointer"
                  onClick={() => togglePasswordVisibility('confirmPassword')}
                >
                  {showPassword.confirmPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-orange-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Continue
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already a member?
            <Link to={'/signin'} className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"> Log in</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
