import { React, useState } from 'react';
import { HiSparkles } from 'react-icons/hi2';
import { BiSolidDish } from 'react-icons/bi';
import { FaQuestionCircle } from "react-icons/fa";
import { IoPeopleSharp } from "react-icons/io5";
import img from '../Images/about1.jpeg';
import img1 from '../Images/about2.jpeg';
import img2 from '../Images/about3.jpeg';
import img3 from '../Images/about.jpeg';
import img4 from '../Images/about4.jpeg';
import { IoIosPeople } from "react-icons/io";
import { PiChefHatBold } from "react-icons/pi";
import { GiLaurelsTrophy } from "react-icons/gi";
import { LiaMedalSolid } from "react-icons/lia";
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

const About = () => {
    return (
        <>
            <Navbar/>
            <div className="container">
                {/* First Column Start */}
                <div className="row">
                    <div className="col-lg-6">
                    <div className='col-lg-12 lg:h-36'></div>
                        <div className="pt-6 pb-6 col-lg-12">
                            <div className="d-flex justify-content-end w-96">
                                <HiSparkles size={30} className="text-orange-500" />
                            </div>
                            <div className='space-y-6'> 
                            <div className="border-2 border-zinc-700  shadow flex items-center space-x-3 rounded-full w-96 p-3">
                                <div>
                                    <FaQuestionCircle size={30} className="text-orange-500" />
                                </div>
                                <div className='text-2xl font-medium'>About  <span className='text-orange-600'> Us</span></div>
                            </div>
                            <div className='flex items-center justify-start'>
                                <div className="px-5 pt-2 space-x-12">

                                    <p>
                                        We are passionate about revolutionizing the dining
                                        experience. Our AI-powered reservation system was
                                        designed with both diners and restaurant owners in
                                        mind, offering a seamless solution to manage reservations,
                                        optimize seating, and enhance customer satisfaction.                                </p>
                                </div>
                            </div>
                            </div>
                            <div className="pt-6 ">
                            <div className="d-flex justify-content-end w-96">
                                <HiSparkles size={30} className="text-orange-500" />
                            </div>
                            <div className="border-2 border-zinc-700  shadow flex items-center space-x-3 rounded-full w-96 p-3">
                                <div>
                                    <FaQuestionCircle size={30} className="text-orange-500" />
                                </div>
                                <div className='text-2xl font-medium'>Our  <span className='text-orange-600'> Mission</span></div>
                            </div>
                            <div className='flex items-center justify-start'>
                                <div className="px-5 pt-2 space-x-12">

                                    <p>
                                        We aim to bridge the gap between restaurants and
                                        diners through cutting-edge AI technology, delivering
                                        personalized, efficient, and user-friendly reservation
                                        experiences. Our goal is to make dining out as enjoyable
                                        as possible by simplifying the process of booking tables
                                        and discovering new restaurants.                               </p>
                                </div>
                            </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="p-5">

                            <img src={img3}
                                alt="Image"
                                className="w-full h-full object-cover"
                            />

                        </div>
                    </div>
                </div>


                {/* First Column End */}
                {/* Second Column Start */}
                <div className="row">
                    <div className="col-lg-6">
                        <div className="p-5">
                            <div className="grid grid-cols-2 gap-4 p-8">
                                {/* Top-left Image */}
                                <div className="rounded-bl-2xl overflow-hidden">
                                    <img
                                        src={img}
                                        alt="Dish"
                                        className="w-full h-full object-cover"
                                    />
                                </div>

                                {/* Top-right Image */}
                                <div className="rounded-tr-2xl overflow-hidden">
                                    <img
                                        src={img1}
                                        alt="Fruit Plate"
                                        className="w-full h-full object-cover"
                                    />
                                </div>

                                {/* Bottom-left Image */}
                                <div className="rounded-bl-2xl overflow-hidden">
                                    <img
                                        src={img2}
                                        alt="Robot Chef"
                                        className="w-full h-full object-cover"
                                    />
                                </div>

                                {/* Bottom-right Image */}
                                <div className="rounded-br-2xl overflow-hidden">
                                    <img
                                        src={img4}
                                        alt="Serving Food"
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">

                        <div className="pt-6 pb-6">
                            <div className="d-flex justify-content-end w-96">
                                <HiSparkles size={30} className="text-orange-500" />
                            </div>
                            <div className="border-2 border-zinc-700 shadow flex items-center space-x-3 rounded-full w-96 p-3">
                                <div>
                                    <BiSolidDish size={30} className="text-orange-500" />
                                </div>
                                <div className='text-2xl font-medium'>What’s Our <span className='text-orange-500'>Process</span></div>
                            </div>
                            <div className="p-5 flex items-center space-x-4">
                                <div className='border-1 rounded-full p-2 shadow-md'>
                                    <HiSparkles size={30} className="text-orange-500" />                                </div>

                                <div>
                                    <h1 className="text-2xl font-sm">
                                        Create Account with us

                                    </h1></div>

                            </div>
                            <div className="px-5 flex items-center space-x-4">
                                <div className='border-1 rounded-full p-2 shadow-md'>
                                    <HiSparkles size={30} className="text-orange-500" />                                </div>

                                <div>
                                    <h1 className="text-2xl font-sm">
                                        Get a unique URL

                                    </h1></div>

                            </div>
                            <div className="p-5 flex items-center space-x-4">
                                <div className='border-1 rounded-full p-2 shadow-md'>
                                    <HiSparkles size={30} className="text-orange-500" />                                </div>

                                <div>
                                    <h1 className="text-2xl font-sm">
                                        Use that URL on social or your website

                                    </h1></div>

                            </div>

                            <div className="px-5 flex items-center space-x-4">
                                <div className='border-1 rounded-full p-2 shadow-md'>
                                    <HiSparkles size={30} className="text-orange-500" />                                </div>

                                <div>
                                    <h1 className="text-2xl font-sm">
                                        User will click on this link and AI call will be initiated

                                    </h1></div>

                            </div>
                            <div className="p-5 flex items-center space-x-4">
                                <div className='border-1 rounded-full p-2 shadow-md'>
                                    <HiSparkles size={30} className="text-orange-500" />                                </div>

                                <div>
                                    <h1 className="text-2xl font-sm">
                                        Reservation will be stored to the Restaurants

                                    </h1></div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* Second Column End */}

                {/* Third Column Start */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="p-5">
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                {/* Image 1 */}
                                <div className=" overflow-hidden m-2" style={{ width: "250px", height: "250px" }}>
                                    <div className="w-full h-full flex justify-center  object-cover rounded-bl-3xl bg-slate-200">
                                        <div className="">
                                            <div className='p-4 px-5 flex justify-center'>
                                                <p className=' border-sky-400 flex justify-center w-20 bg-orange-600 rounded-full border p-3'>
                                                    <IoIosPeople size={40} color='white' />
                                                </p>
                                            </div>
                                            <div className=' flex justify-center p-2'>
                                                <h1 className='text-2xl font-bold text-orange-600'>15k+</h1>
                                            </div>
                                            <div>
                                                <p className='text-2xl font-medium'>
                                                    Happy <span className='text-orange-600'> Customers</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Image 2 */}
                                <div className=" overflow-hidden m-2 " style={{ width: "250px", height: "250px" }}>
                                    <div className="w-full h-full flex justify-center  object-cover rounded-bl-3xl bg-slate-200">
                                        <div className="">
                                            <div className='p-4 px-5'>
                                                <p className=' border-sky-400 flex justify-center bg-orange-600 rounded-full border p-3'>
                                                    <PiChefHatBold size={40} color='white' />
                                                </p>
                                            </div>
                                            <div className=' flex justify-center p-2'>
                                                <h1 className='text-2xl font-bold text-orange-600'>100+</h1>
                                            </div>
                                            <div>
                                                <p className='text-2xl font-medium'>
                                                    Passionate  <span className='text-orange-600'> Chef</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Image 3 */}
                                <div className=" overflow-hidden m-2" style={{ width: "250px", height: "250px" }}>
                                    <div className="w-full h-full flex justify-center  object-cover rounded-bl-3xl bg-slate-200">
                                        <div className="">
                                            <div className='p-4 px-5 flex justify-center'>
                                                <p className=' border-sky-400 w-20 flex justify-center bg-orange-600 rounded-full border p-3'>
                                                    <GiLaurelsTrophy size={40} color='white' />
                                                </p>
                                            </div>
                                            <div className=' flex justify-center p-2'>
                                                <h1 className='text-2xl font-bold text-orange-600'>100+</h1>
                                            </div>
                                            <div>
                                                <p className='text-2xl font-medium'>
                                                    Years of   <span className='text-orange-600'> Experience</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Image 4 */}
                                <div className=" overflow-hidden m-2" style={{ width: "250px", height: "250px" }}>
                                    <div className="w-full h-full flex justify-center  object-cover rounded-bl-3xl bg-slate-200">
                                        <div className="">
                                            <div className='p-4 px-5 flex justify-center'>
                                                <p className=' border-sky-400 flex justify-center w-20 bg-orange-600 rounded-full border p-3'>
                                                    <LiaMedalSolid size={40} color='white' />
                                                </p>
                                            </div>
                                            <div className=' flex justify-center p-2'>
                                                <h1 className='text-2xl font-bold text-orange-600'>100+</h1>
                                            </div>
                                            <div>
                                                <p className='text-2xl font-medium'>
                                                    Deserved   <span className='text-orange-600'> Awards</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Third Column End */}
            </div>
            <Footer/>
        </>
    )
}

export default About
