import { React, useState,useEffect, useRef } from 'react';
import { HiSparkles } from 'react-icons/hi2';
import { BiSolidDish } from 'react-icons/bi';
import { FaQuestionCircle } from "react-icons/fa";
import { IoPeopleSharp } from "react-icons/io5";
import img from '../Images/top-view-table-full-delicious-food-composition.jpg';
import img1 from '../Images/foood.jpg';
import img2 from '../Images/scafdcv.jpg';
import img3 from '../Images/image_1.png';
import img4 from '../Images/image_2.png';
import kfc from '../Images/pngimg.com - kfc_PNG34.png'
import mcd from '../Images/macdoland.png'
import sub from '../Images/subway.png'
import dom from '../Images/dominos.jpg'
import hen from '../Images/hen.png'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'


const Home = () => {
    const [activeIndex, setActiveIndex] = useState(2); // Default to the middle logo
    const containerRef = useRef(null);
    const logos = [
        { src: hen, name: 'Hen n\'bun', description: 'Food Enthusiast' },
        { src: mcd, name: 'McDonald\'s', description: 'Fast Food Chain' },
        { src: kfc, name: 'KFC', description: 'Food Enthusiast' },
        { src: dom, name: 'Domino\'s Pizza', description: 'Pizza Lover' },
        { src: sub, name: 'Subway', description: 'Fresh Food' }
    ];
    // Function to handle moving to the previous logo
    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? logos.length - 1 : prevIndex - 1));
    };

    // Function to handle moving to the next logo
    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex === logos.length - 1 ? 0 : prevIndex + 1));
    };

    
      useEffect(() => {
        if (containerRef.current) {
          const container = containerRef.current;
          const containerWidth = container.offsetWidth;
          const itemWidth = container.children[0].offsetWidth;
          const centerIndex = Math.floor(container.children.length / 2);
          const offset = (itemWidth * (activeIndex - centerIndex)) + (containerWidth / 2 - itemWidth / 2);
    
          // Ensure the scroll position is within bounds
          const maxScrollLeft = container.scrollWidth - containerWidth;
          container.scrollLeft = Math.min(Math.max(0, offset), maxScrollLeft);
        }
      }, [activeIndex]);

    const [activeFaqIndex, setActiveFaqIndex] = useState(null); // To manage the visibility of FAQs

    // Function to toggle visibility of FAQ
    const toggleFaq = (index) => {
        setActiveFaqIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const questions = [
        "Can I Modify or Cancel My Reservation?",
        "What if the restaurant is fully booked?",
        "How do I receive a reservation confirmation?",
        "Can I book for a large group or special occasion?",
        "Is my personal information safe?"
      ];
      
      const answers = [
        "Yes, you can modify or cancel your reservation at any time by accessing your account settings or contacting customer support.",
        "If the restaurant is fully booked, our system will suggest alternative times or nearby restaurants with available tables.",
        "You will receive a reservation confirmation via email or SMS, depending on your preferred contact method.",
        "Yes, you can book for large groups or special occasions. Please provide details in the reservation request, and we will accommodate you as best as possible.",
        "Yes Your personal information is safe with us"
      ];
      
    return (
        <>
        <Navbar/>

        <div className="container">
            {/* First Column Start */}
            <div className="row">
                <div className="col-lg-6 ">
                    <div className='col-lg-12 lg:h-36'></div>
                    <div className="pt-6 pb-6 className='col-lg-12'">
                        <div className="d-flex justify-content-end w-96">
                            <HiSparkles size={30} className="text-orange-500" />
                        </div>
                        <div className="border-2 border-zinc-700 shadow flex items-center space-x-3 rounded-full w-96 p-3">
                            <div>
                                <BiSolidDish size={30} className="text-orange-500" />
                            </div>
                            <div>The Best AI Restaurant Reservation</div>
                        </div>
                        <div className="p-5 lg:space-y-5">
                            <h1 className="text-4xl">
                                Automate Your Reservation with
                                <br />
                                <span className="text-orange-500">AI Restaurant Reservation</span>
                            </h1>
                            <p>
                                Planning your next dining experience has never been easier. We bring the power of AI to your fingertips,
                                offering an effortless way to reserve your favorite restaurants anytime, anywhere. Whether you're
                                celebrating a special occasion, looking for a last-minute table, or craving something specific, our smart
                                reservation system is here to serve you.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 ">
                    <div className="p-5">
                        <div className="grid grid-cols-2 gap-4 p-8">
                            {/* Top-left Image */}
                            <div className="rounded-bl-2xl overflow-hidden">
                                <img
                                    src={img}
                                    alt="Dish"
                                    className="w-full h-full object-cover"
                                />
                            </div>

                            {/* Top-right Image */}
                            <div className="rounded-tr-2xl overflow-hidden">
                                <img
                                    src={img1}
                                    alt="Fruit Plate"
                                    className="w-full h-full object-cover"
                                />
                            </div>

                            {/* Bottom-left Image */}
                            <div className="rounded-bl-2xl overflow-hidden">
                                <img
                                    src={img2}
                                    alt="Robot Chef"
                                    className="w-full h-full object-cover"
                                />
                            </div>

                            {/* Bottom-right Image */}
                            <div className="rounded-br-2xl overflow-hidden">
                                <img
                                    src={img4}
                                    alt="Serving Food"
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* First Column End */}
            {/* Second Column Start */}

            <div className="row">
                <div className="col-lg-6">
                    <div className="pt-6 pb-6">
                        <div className="d-flex justify-content-end w-96">
                            <HiSparkles size={30} className="text-orange-500" />
                        </div>
                        <div className="border-2 border-zinc-700 shadow flex items-center space-x-3 rounded-full w-96 p-3">
                            <div>
                                <FaQuestionCircle size={30} className="text-orange-500" />
                            </div>
                            <div className='text-2xl font-medium'>Why <span className='text-orange-600'> Choose Us</span></div>
                        </div>
                        <div className='flex items-center justify-start'>
                            <div className="px-5 pt-5 space-x-12">
                                <div className=" flex items-center ">
                                    <div className=' p-2 shad1 rounded-full mr-2.5'>
                                        <BiSolidDish size={30} className="text-orange-500" /></div>
                                    <div className='text-2xl'>
                                        Seamless Booking:
                                    </div>
                                </div>
                                <p>
                                    Reserve tables at top restaurants instantly, with real-time availability.
                                </p>
                            </div>
                        </div>
                        <div className='flex items-center justify-start'>
                            <div className=" px-5 space-x-12">
                                <div className=" flex items-center ">
                                    <div className=' p-2 shad1 rounded-full mr-2.5'>
                                        <BiSolidDish size={30} className="text-orange-500" /></div>
                                    <div className='text-2xl'>
                                        Personalized Experience:
                                    </div>
                                </div>
                                <p>
                                    Get recommendations tailored to your tastes and past preferences.
                                </p>
                            </div>
                        </div>

                        <div className='flex items-center justify-start'>
                            <div className="px-5 space-x-12">
                                <div className=" flex items-center ">
                                    <div className=' p-2 shad1 rounded-full mr-2.5'>
                                        <BiSolidDish size={30} className="text-orange-500" /></div>
                                    <div className='text-2xl'>
                                        Smart Reminders:
                                    </div>
                                </div>
                                <p>
                                    Receive automated reminders for your reservations and notifications
                                    of special events or offers.
                                </p>
                            </div>
                        </div>
                        <div className='flex items-center justify-start'>
                            <div className="px-5 space-x-12">
                                <div className=" flex items-center ">
                                    <div className=' p-2 shad1 rounded-full mr-2.5'>
                                        <BiSolidDish size={30} className="text-orange-500" /></div>
                                    <div className='text-2xl'>
                                        24/7 Service:
                                    </div>
                                </div>
                                <p>
                                    Book your table at any hour—no need to wait for business hours or calls.
                                </p>
                            </div>
                        </div>
                        <div className='flex items-center justify-start'>
                            <div className="px-5 space-x-12">
                                <div className=" flex items-center ">
                                    <div className=' p-2 shad1 rounded-full mr-2.5'>
                                        <BiSolidDish size={30} className="text-orange-500" /></div>
                                    <div className='text-2xl'>
                                        Efficient Table Management:
                                    </div>
                                </div>
                                <p>
                                    We help restaurants optimize seating and ensure you get the best dining
                                    experience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 ">
                    <div className="p-5">

                        <img src={img3}
                            alt="Image"
                            className="w-full h-full object-cover"
                        />

                    </div>
                </div>
            </div>
            {/* Second Column End */}

            {/* Testimonial Start */}

            <div className="w-96">
                <div className="d-flex justify-content-end w-96">
                    <HiSparkles size={30} className="text-orange-500" />
                </div>
                <div className="border-2 border-zinc-700 shadow flex items-center space-x-3 rounded-full w-full max-w-5xl mx-auto p-3">
                    <div>
                        <IoPeopleSharp size={30} className="text-orange-500" />
                    </div>
                    <div className="text-2xl font-medium">
                        Our <span className="text-orange-600">Partners</span>
                    </div>
                </div>
            </div>
            <div className="w-full  relative">
          <button
            onClick={handlePrev}
            className="arrow-button left"
          >
            <span className="text-xl">←</span>
          </button>

          {/* Logo Items Container */}
          <div
            ref={containerRef}
            className="logo-container"
          >
            <div className="logo-wrapper">
              {logos.map((logo, index) => (
                <div
                  key={index}
                  className={`logo-item ${index === activeIndex ? 'active' : ''}`}
                >
                  <img
                    src={logo.src}
                    alt={`Logo ${index + 1}`}
                    className="logo-img"
                  />
                  {index === activeIndex && (
                    <div className="mt-2 text-center">
                      <p className="font-bold text-sm">{logo.name}</p>
                      <p className="text-xs text-gray-500">{logo.description}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={handleNext}
            className="arrow-button right"
          >
            <span className="text-xl">→</span>
          </button>
        </div>


            {/* Testimonial End */}

            {/* Third Column Start */}

            <div className="row">
                <div className="col-lg-12">
                    <div className="pt-6 pb-6">
                        <div className="d-flex justify-content-end w-96">
                            <HiSparkles size={30} className="text-orange-500" />
                        </div>
                        <div className="border-2 border-zinc-700 shadow flex items-center space-x-3 rounded-full w-96 p-3">
                            <div>
                                <FaQuestionCircle size={30} className="text-orange-500" />
                            </div>
                            <div className="text-2xl font-medium">FAQ’s</div>
                        </div>
                        <div className="p-5 space-y-5">
      {questions.map((question, index) => (
        <div key={index}>
          <button
            onClick={() => toggleFaq(index)}
            className="text-2xl border-2 justify-between border-zinc-700 shadow flex items-center space-x-3 rounded-full w-full p-3 cursor-pointer"
          >
            <span>{question}</span>
            <span>{activeFaqIndex === index ? '-' : '+'}</span>
          </button>

          {/* Answer Section */}
          <div
            className={`overflow-hidden transition-all duration-500 shadow-lg rounded-full ease-in-out ${
              activeFaqIndex === index ? "max-h-40 p-4" : "max-h-0"
            }`}
          >
            <p className="text-lg">{answers[index]}</p>
          </div>
        </div>
      ))}
    </div>
                    </div>
                </div>
            </div>

            {/* Third Column End */}

        </div>
        <Footer/>
        </>
    );
};

export default Home;
