import React from 'react'
import {Link} from 'react-router-dom'
const Footer = () => {
  return (
    <>
      <div className='bg-orange-500 py-10 rounded-t-full'>
        <div className='flex justify-center space-x-32 lg:space-x-36 text-white'>
            <p className=' text-xl font-medium	'><Link to={'/'}>Home</Link></p>
            <p className=' text-xl font-medium	'><Link to={'/about'}>About</Link></p>
        </div>

      </div>

    </>
  )
}

export default Footer
