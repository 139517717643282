import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import img from '../Images/AI_RESERVATION_LOGO-02-removebg-preview.png';

const Navbar = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [visibleCount, setVisibleCount] = useState(10); // Number of notifications to show
  const [error, setError] = useState(null);

  // Check if the user is logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  // Fetch notifications from the API
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found in local storage');
          return;
        }

        const response = await axios.get('https://backend.techionik.com/api/notifications/', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        setNotifications(response.data);
      } catch (err) {
        setError('Failed to fetch notifications');
        console.error('Error fetching notifications:', err);
      }
    };

    if (isLoggedIn) {
      fetchNotifications();
    }
  }, [isLoggedIn]);

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    navigate('/signin');
  };

  // Function to load more notifications
  const loadMoreNotifications = (event) => {
    event.stopPropagation(); // Prevent the dropdown from closing
    setVisibleCount((prevCount) => prevCount + 10); // Increase the count by 10
  };

  return (
    <>
      <header>
        <div className="p-3 text-center bg-white border-bottom">
          <div className="container">
            <div className="row">
              {/* Left elements */}
              <div className="col-md-2 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                <Link to={'/'}>
                  <img src={img} height={35} alt="Logo" />
                </Link>
              </div>

              {/* Center elements */}
              <div className="col-md-7 d-flex justify-center mt-2 space-x-36">
                <p className="text-xl font-medium">
                  <Link to={'/'}>Home</Link>
                </p>
                <p className="text-xl font-medium">
                  <Link to={'/about'}>About</Link>
                </p>
              </div>

              {/* Right elements */}
              <div className="col-md-3 d-flex justify-content-center justify-content-md-end align-middle">
                <div className="d-flex">
                  {!isLoggedIn ? (
                    <div className="d-flex">
                      <button className="btn rounded-none border-r-0 border-orange-400 hover:border-blue-600">
                        <Link to={'/signin'}>SignIn</Link>
                      </button>
                      <button className="btn pl-0 rounded-none border-l-0 border-orange-400 hover:border-blue-600">
                        <Link to={'/signup'}>SignUp</Link>
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center col-lg-10 col-md-10 col-10">
                      {/* Notifications Dropdown */}
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Notifications
                        </button>
                        <ul
                          className="dropdown-menu notification-dropdown"
                          aria-labelledby="dropdownMenuButton"
                          onClick={(e) => e.stopPropagation()} // Prevent closing when interacting with the dropdown content
                        >
                          {notifications.length > 0 ? (
                            notifications.slice(0, visibleCount).map((notification, index) => (
                              <li key={index} className="dropdown-item">
                                <div className={`notification ${notification.is_read ? '' : 'font-bold'} text-wrap`}>
                                  {notification.formatted_message} <br />
                                  <small className="text-muted">
                                    {new Date(notification.created_at).toLocaleString()}
                                  </small>
                                </div>
                              </li>
                            ))
                          ) : (
                            <li>
                              <a className="dropdown-item" href="#">
                                No new notifications
                              </a>
                            </li>
                          )}
                          {visibleCount < notifications.length && (
                            <li className="px-10">
                              <button
                                className="dropdown-item text-white text-center bg-orange-500 px-10 hover:bg-orange-600"
                                onClick={loadMoreNotifications} // Prevent dropdown from closing
                              >
                                Load More
                              </button>
                            </li>
                          )}
                        </ul>
                      </div>

                      {/* Profile Dropdown */}
                      <div className="space-x-4 flex">
                        <div className="ms-3">
                          <Link to={'/profile'}>
                            <button className="btn btn-dark bg-slate-950 text-white" type="button">
                              Profile
                            </button>
                          </Link>
                        </div>
                        <div>
                          <button
                            className="btn btn-warning bg-orange-500 text-white"
                            type="button"
                            onClick={handleLogout}
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
