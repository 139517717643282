import React, { useState, useEffect } from 'react';

const Thanks = () => {
    const [timeLeft, setTimeLeft] = useState(5); // Time in seconds

    useEffect(() => {
        // Set up a timer to update the countdown every second
        const countdown = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    // When the timer hits 0, attempt to close the window
                    clearInterval(countdown);
                    // Attempt to close the tab
                    if (window.opener) {
                        window.close();
                    } else {
                        try {
                            window.close();
                        } catch (e) {
                            console.warn('Window close failed:', e);
                        }
                    }
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000); // 1000 ms = 1 second

        // Clear the interval if the component is unmounted before the timer is done
        return () => clearInterval(countdown);
    }, []);

    return (
        <>
        <div className="flex items-center justify-center h-screen">
            <h1 className="text-center text-xl font-bold">
                Thank you for calling, we'll get back to you soon.
                <br/>
                <div className='flex items-center justify-center ' 
                > Window will close in <div className='text-sm font-normal border-4 animated-border  rounded-full shadow-lg h-20 w-20 flex items-center justify-center mx-3'
              ><h1 className='loader-text'>{timeLeft}s</h1></div></div>
            </h1>
            

          
        </div>
          <div className="flex items-center justify-center">
      </div>
      </>
    );
};

export default Thanks;
