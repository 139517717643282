import React, { useState, useEffect } from 'react';
import { BsPeopleFill } from "react-icons/bs";
import { CiCalendar } from "react-icons/ci";
import { MdAccessTime } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import img from '../Images/about2.jpeg';
import axios from 'axios'; // Make sure axios is imported

const Reservations = () => {
  const reservationsPerPage = 4; // Number of reservations per page
  const [currentPage, setCurrentPage] = useState(1);
  const [reservations, setReservations] = useState([]); // Store the fetched reservations
  const [error, setError] = useState(null);

  // Fetch reservations from the API
  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://backend.techionik.com/api/notifications/', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setReservations(response.data); // Assuming response.data is an array of reservations
      } catch (err) {
        setError('Failed to fetch reservations');
        console.error('Error fetching reservations:', err);
      }
    };

    fetchReservations();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getVisibleReservations = () => {
    const startIndex = (currentPage - 1) * reservationsPerPage;
    const endIndex = startIndex + reservationsPerPage;
    return reservations.slice(startIndex, endIndex); // Show only the reservations for the current page
  };

  const totalPages = Math.ceil(reservations.length / reservationsPerPage);

  return (
    <div className="w-full bg-white rounded-lg p-5 md:p-6 lg:p-8">
      <div className="p-5">
        {/* Title Section */}
        <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-5">
          <h2 className="text-xl md:text-2xl font-bold mb-2 md:mb-0">
            YOU'RE ONGOING RESERVATIONS
          </h2>
          <button className="bg-orange-500 text-white px-4 py-2 rounded text-sm md:text-base">
            ALL RESERVATIONS
          </button>
        </div>

        {error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <div className="space-y-4">
            {/* Check if reservations exist */}
            {reservations.length > 0 ? (
              getVisibleReservations().map((reservation, index) => (
                <div
                  key={reservation.id}
                  className={`flex flex-col md:flex-row items-center justify-between p-4 border border-gray-200 rounded-lg ${
                    index === 1 ? 'bg-white' : 'bg-white'
                  }`}
                >
                  {/* Profile Section */}
                  <div className="flex items-center space-x-3 mb-4 md:mb-0">
                    <img
                      src={img} // Placeholder for avatar
                      alt="User Avatar"
                      className="w-10 h-10 rounded-full"
                    />
                    <div>
                      <p className="font-semibold">{reservation.formatted_message || 'Unknown User'}</p>
{/*                       <p className="text-sm text-gray-500">{reservation.customer_email || 'No Email'}</p>
 */}                    </div>
                  </div>

                  {/* Reservation Details */}
               {/*    <div className="flex flex-wrap gap-4 justify-start md:justify-center text-gray-700 mb-4 md:mb-0">
                    <div className="flex items-center text-sm md:text-base lg:border-l-2 lg:border-l-slate-950 px-2">
                      <FaPhoneAlt className='text-orange-500' size={25} />
                      <p className="font-medium">{reservation.contact_user || 'No Contact'}</p>
                    </div>
                    <div className="flex items-center text-sm md:text-base lg:border-l-2 lg:border-l-slate-950 px-2">
                      <BsPeopleFill className='text-orange-500' size={25} />
                      <span className='font-medium'>{reservation.people_count || 0}</span>
                    </div>
                    <div className="flex items-center text-sm md:text-base lg:border-l-2 lg:border-l-slate-950 px-2">
                      <CiCalendar className='text-orange-500' size={25} />
                      <span className='font-medium'>{reservation.reservation_date || 'N/A'}</span>
                    </div>
                    <div className="flex items-center text-sm md:text-base lg:border-l-2 lg:border-l-slate-950 px-2">
                      <MdAccessTime className='text-orange-500' size={25} />
                      <span className='font-medium'>{reservation.reservation_time || 'N/A'}</span>
                    </div>
                  </div> */}

                  {/* Menu and More Options */}
                  <div className="flex space-x-2">
                    <button className="text-gray-500">
                      <i className="fas fa-ellipsis-h"></i>
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No reservations available.</p>
            )}
          </div>
        )}

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="flex flex-wrap justify-center items-center mt-5 gap-2">
            {currentPage > 1 && (
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className="bg-gray-200 px-3 py-1 rounded text-gray-500 text-sm md:text-base"
              >
                &lt;
              </button>
            )}
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`px-3 py-1 border border-gray-300 rounded text-gray-500 text-sm md:text-base ${
                  currentPage === index + 1 ? 'bg-orange-500 text-white' : ''
                }`}
              >
                {index + 1}
              </button>
            ))}
            {currentPage < totalPages && (
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className="bg-gray-200 px-3 py-1 rounded text-gray-500 text-sm md:text-base"
              >
                &gt;
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Reservations;
