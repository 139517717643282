import React, { useEffect, useState, useRef } from "react";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import axios from "axios";

const Screen = () => {
  const [animationActive, setAnimationActive] = useState(true);
  const [dots, setDots] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const [isAudioPlayed, setIsAudioPlayed] = useState(false);
  const [timer, setTimer] = useState(0); // Timer state
  const [showTimer, setShowTimer] = useState(false); // Toggle timer display
  const [isMuted, setIsMuted] = useState(false); // Mute/unmute state
  const [audioPosted, setAudioPosted] = useState(false); // Track if the audio is posted
  const [isSaving, setIsSaving] = useState(false); // For showing loader on Save button
  const [audioReady, setAudioReady] = useState(false); // Track if audio is ready to play

  const audioRef = useRef(null); // To store the audio object
  const audioFetched = useRef(false); // Ref to ensure audio is fetched only once
  const timerRef = useRef(null); // To store the timer interval reference
  const restaurantName = localStorage.getItem('restaurantName');

  // Detects key press to abort the call
  useEffect(() => {
    const handleKeyPress = () => {
      if (audioPosted || isSaving) {
        setAnimationActive(false);
        stopTimer();

        // Redirect to /done in the same window
        window.open('/done', '_blank');

        // Close the window after a short delay to ensure the redirect happens
        setTimeout(() => {
          window.close();
        }, 100); // Adjust delay if necessary
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [audioPosted, isSaving]);

  // Animation for the dots (initiating...)
  useEffect(() => {
    if (!animationActive) return;
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length === 3 ? "" : prevDots + "."));
    }, 500);

    return () => clearInterval(interval);
  }, [animationActive]);

  // Timer management function
  const startTimer = () => {
    setShowTimer(true);
    timerRef.current = setInterval(() => {
      setTimer((prevTime) => prevTime + 1);
    }, 1000); // Increment timer every second
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  // Start recording after the audio ends
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      setAudioRecorder(mediaRecorder);

      const audioChunks = [];
      mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/mp3" });
        setRecordedBlob(audioBlob);
      };
      mediaRecorder.start();
      setIsRecording(true); // Update state
    } catch (error) {
      console.error("Error starting audio recording:", error);
    }
  };

  // Stop the recording when saveRecording is clicked
  const stopRecording = () => {
    if (audioRecorder) {
      audioRecorder.stop();
      setIsRecording(false);
    }
  };

  const sendRecordedFile = async (audioBlob) => {
    console.log("Attempting to upload audio file...");
    const reservationId = localStorage.getItem("reservationId");

    // Create FormData and append the audio file with the key 'audio_file'
    const formData = new FormData();
    formData.append("audio_file", audioBlob, "recording.mp3"); // Updated to use 'audio_file' key

    try {
      const response = await axios.post(
        `https://backend.techionik.com/api/reservations/${reservationId}/transcribe/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Audio file uploaded successfully:", response.data);
      setAudioPosted(true); // Mark the audio as posted
    } catch (error) {
      console.error("Error uploading audio file:", error.response?.data || error.message);
    }
  };

  // Save the recording and stop if still recording
  const saveRecording = async () => {
    if (isRecording) {
      stopRecording(); // Stop the recording before saving
    }

    if (recordedBlob) {
      setIsSaving(true); // Show the loader
      console.log("Recording blob is available, proceeding to save...");

      // Send the recorded file to the API
      await sendRecordedFile(recordedBlob);

      setIsSaving(false); // Hide the loader
      alert("Your message was sent");
    } else {
      console.error("No recording available to save or post.");
    }
  };

  // Close tab function
  const closeTab = () => {
    window.close(); // Forcefully closes the tab
  };

  // Fetch and prepare audio, but don't autoplay
  useEffect(() => {
    const fetchAudio = async () => {
      if (audioFetched.current || isLoadingAudio || isAudioPlayed) return; // Prevent multiple requests or replays

      audioFetched.current = true; // Mark as fetched
      setIsLoadingAudio(true);

      try {
        const response = await axios.get(
          `https://backend.techionik.com/api/serve-audio/${restaurantName}.mp3/`,
          { responseType: "blob" }
        );

        const audioBlob = new Blob([response.data], { type: "audio/mp3" });
        const audioUrl = URL.createObjectURL(audioBlob);
        audioRef.current = new Audio(audioUrl);

        audioRef.current.oncanplaythrough = () => {
          setAudioReady(true); // Audio is ready to be played
        };

        audioRef.current.onended = () => {
          console.log("Audio finished playing");
          startRecording(); // Automatically start recording after the audio ends
        };
      } catch (error) {
        console.error("Error fetching audio:", error.response || error.message);
      } finally {
        setIsLoadingAudio(false);
      }
    };

    fetchAudio();
  }, [restaurantName, isAudioPlayed]);

  // Toggle microphone mute/unmute
  const toggleMute = () => {
    if (audioRecorder && audioRecorder.state === "recording") {
      audioRecorder.stream.getTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
      setIsMuted(!isMuted);
    }
  };

  // Format timer to display as mm:ss
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="relative">
        <div
          className={`w-24 h-24 rounded-full border-4 ${
            animationActive ? "animate-spin" : ""
          }`}
          style={{
            borderTopColor: "#F97316",
            borderRightColor: "#EF4444",
            borderBottomColor: "#3B82F6",
            borderLeftColor: "#10B981",
          }}
        ></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center transition ease-in-out duration-500">
            {/* Display the timer if audio has started playing, otherwise show initiating dots */}
            <h1 className="text-center">
              {showTimer ? formatTime(timer) : `Initiating${dots}`}<br />
              {isRecording ? " Recording..." : ""}
            </h1>
          </div>
        </div>
      </div>

      <p className="text-center text-gray-600 mt-4">Press Any Key to Abort the Call</p>

      <div className="mt-3 flex justify-center space-x-3">
        {/* Speaker button */}
        <button className="bg-orange-600 p-2 rounded-lg text-white">
          <HiMiniSpeakerWave size={30} className="text-white" />
        </button>

        {/* Microphone button for mute/unmute */}
        <button className="bg-orange-600 p-2 rounded-lg" onClick={toggleMute}>
          {isMuted ? (
            <FaMicrophoneSlash size={30} className="text-white" />
          ) : (
            <FaMicrophone size={30} className="text-white" />
          )}
        </button>
      </div>

      {/* Play audio button */}
      

      {/* Save recording button */}
      <div className="flex space-x-3">
        <button
          className="bg-blue-600 mt-4 text-white py-2 px-4 hhh rounded"
          onClick={saveRecording}
          disabled={(!isRecording && !recordedBlob) || isSaving} // Disable if not recording, no blob, or saving
        >
          {isSaving ? "Ending..." : "End Call"}
        </button>

        {/* Close tab button */}
        {audioReady && !isAudioPlayed && (
        <button
          className="bg-green-600 mt-4 text-white py-2 px-4 rounded"
          onClick={() => {
            audioRef.current.play();
            setIsAudioPlayed(true);
            startTimer();
          }}
        >
          Start Call
        </button>
      )}
      </div>
    </div>
  );
};

export default Screen;
