import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ContactForm = () => {
  const { uniqueUrl } = useParams(); // Extract uniqueUrl from URL
  const [restaurantProfile, setRestaurantProfile] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: ''
  });
  const [loading, setLoading] = useState(false);

  // Fetch all restaurants and find the one matching the uniqueUrl
  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get('https://backend.techionik.com/api/restaurants');
        const restaurants = response.data;

        // Find the restaurant with a matching unique_url
        const matchingRestaurant = restaurants.find(
          (restaurant) => restaurant.unique_url === uniqueUrl
        );

        if (matchingRestaurant) {
          setRestaurantProfile(matchingRestaurant); // Set the matched restaurant profile
        } else {
          console.error("Unique URL does not match any restaurant.");
        }
      } catch (error) {
        console.error('Error fetching restaurants:', error.response ? error.response.data : error.message);
      }
    };

    if (uniqueUrl) {
      fetchRestaurants(); // Only fetch if uniqueUrl is available
    }
  }, [uniqueUrl]);

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

 // Handle form submission (POST API)
 const handleFormSubmit = async (event) => {
  event.preventDefault();
  setLoading(true); // Start loading

  try {
    if (!uniqueUrl) {
      console.error('Unique URL is not provided');
      setLoading(false);
      return;
    }

    // Data to be sent to the backend
    const postData = {
      name: formData.name,
      user_email: formData.email,
      contact_user: formData.phone,
    };

    // POST request to the API, no token needed
    const response = await axios.post(`https://backend.techionik.com/api/restaurants/${uniqueUrl}/profile/`, postData);
    console.log("Form submitted successfully:", response.data);

    // Extract the reservation id from the response
    const reservationId = response.data.reservation.id;

    // Save the ID to localStorage
    localStorage.setItem('reservationId', reservationId);
    console.log(`Reservation ID ${reservationId} saved to localStorage`);

    // Store restaurantName in localStorage for the new window
    localStorage.setItem('restaurantName', restaurantProfile.restaurant_name);

    // Open the Screen component in a new tab
    window.open('/initiating', '_blank');
  } catch (error) {
    console.error("Error submitting form data:", error.response ? error.response.data : error.message);
  } finally {
    setLoading(false); // Stop loading
  }
};


  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
        <h2 className="text-center text-xl font-bold mb-6">
          {restaurantProfile ? `You are talking with ${restaurantProfile.restaurant_name}` : "Loading..."}
        </h2>

        <form className="space-y-5" onSubmit={handleFormSubmit}>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
              placeholder="Name"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Phone Number:</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
              placeholder="Phone Number"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">E-mail:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
              placeholder="Email"
            />
          </div>
          <div className="flex justify-center items-center">
            <button
              type="submit"
              className={`w-full px-4 py-2 border border-gray-300 rounded bg-orange-500 text-white ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-orange-600'}`}
              disabled={loading} // Disable button when loading
            >
              {loading ? 'Submitting...' : 'Submit'} {/* Toggle text */}
            </button>
          </div>
          <div className="flex justify-center font-bold text-sm">
            SUBMIT THIS DETAIL TO INITIATE THE CALL.
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
