import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signin from './Pages/Signin';
import Signup from './Pages/Signup';
import Home from './Pages/Home';
import About from './Pages/About';
import Profile from './Pages/Profile';
import ContactForm from './Pages/ContactForm';
import Screen from './Pages/Screen'
import Thanks from './Components/Thanks';

function App() {
  return (
    <>
     <Router>
<Routes> 
<Route path='/signin' element={ <Signin/>}/>
<Route path='/signup' element={ <Signup/>}/>
<Route path='/' element={ <Home/>}/>
<Route path='/about' element={ <About/>}/>
<Route path='/profile' element={ <Profile/>}/>
<Route path='/contact/:uniqueUrl' element={ <ContactForm/>}/>
<Route path='/initiating' element={ <Screen/>}/>
<Route path='/done' element={ <Thanks/>}/>

    </Routes>
    </Router>
    </>
  );
}

export default App;
