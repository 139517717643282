import React, { useState, useEffect } from 'react';
import img from '../Images/about1.jpeg';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { IoMdPerson } from "react-icons/io";
import { FaRegCalendarCheck } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import axios from 'axios';

const Sidebar = ({ setActiveSection, activeSection }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [profileData, setProfileData] = useState({
    email: '',
    restaurant_name: '',
    restaurant_contact_number: '',
  });

  // Toggle Sidebar function
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Fetch Profile Data from API
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
        const response = await axios.get('https://backend.techionik.com/api/profile/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Set the fetched profile data
        const { email, restaurant_name, restaurant_contact_number } = response.data;
        setProfileData({
          email,
          restaurant_name,
          restaurant_contact_number,
        });
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, []);

  return (
    <>
      <div className=' lg:fixed '>
        <div className="lg:hidden fixed flex justify-between items-center pl-4 pt-2 text-orange-500">
          <button onClick={toggleSidebar}>
            <AiOutlineMenu size={24} color="orange" />
          </button>
        </div>

        {/* Sidebar */}
        <div
          className={`fixed top-0 left-0 w-64 h-screen bg-orange-500 flex flex-col items-center transition-transform transform ${
            isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          } lg:translate-x-0 lg:relative lg:w-full`}
        >
          {/* Close Button */}
          <button
            className="lg:hidden absolute top-4 right-4 text-white text-2xl"
            onClick={toggleSidebar}
          >
            <AiOutlineClose />
          </button>

          <div className='m-3 space-y-3'>
            <div className='flex justify-center'>
              <img src={img} className='rounded-full w-48 h-48' alt="Profile" />
            </div>
            <div>
              <h2 className='flex justify-center text-2xl text-white'>
                {profileData.restaurant_name || 'Restaurant Name'}
              </h2>
            </div>
            <div className='flex justify-center space-x-2 text-xl text-white'>
              <div><FaPhoneAlt size={25} /></div>
              <p>{profileData.restaurant_contact_number || '+1234567890'}</p>
            </div>
            <div className='flex justify-center space-x-2 text-xl text-white'>
              <div><MdEmail size={25} /></div>
              <p>{profileData.email || 'example@example.com'}</p>
            </div>
          </div>

          <button
            className={`w-full flex justify-center py-3 border-b-2 border-blue-50 ${activeSection === 'section1' ? 'bg-white text-orange-500' : 'bg-orange-500 text-white'}`}
            onClick={() => setActiveSection('section1')}
          >
            <IoMdPerson size={25} />
            <span className='font-bold mx-2'>PROFILE</span>
          </button>
          <button
            className={`w-full flex justify-center py-3 border-b-2 border-blue-50 ${activeSection === 'section2' ? 'bg-white text-orange-500' : 'bg-orange-500 text-white'}`}
            onClick={() => setActiveSection('section2')}
          >
            <FaRegCalendarCheck size={25} />
            <span className='font-bold mx-2'>RESERVATIONS</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
